import {StyleSheet} from 'react-native';
export default StyleSheet.create({
  root: {
    width: 35,
    height: 35,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  disabled: {
    opacity: 0.5,
  },
});
