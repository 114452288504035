export default [
  'ALD Automotive',
  'Alphabet',
  'Arval',
  'Athlon',
  'Business Lease',
  'LeasePlan',
  'Overig',
].sort((a, b) => {
  if (a === 'Overig') {
    return 1;
  }

  if (b === 'Overig') {
    return -1;
  }

  return a.localeCompare(b);
});
