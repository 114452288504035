import React, {useState} from 'react';
import {View, ScrollView} from 'react-native';
import _ from 'lodash';

import FormTextInput from '../FormTextInput';
import styles from './styles';
import {Menu, TouchableRipple, TextInput} from 'react-native-paper';

const Select = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputLayout, setInputLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  const onLayout = (event) => {
    setInputLayout(event.nativeEvent.layout);
  };

  const onOptionPress = (option) => {
    setIsOpen(false);

    if (props.onChange) {
      props.onChange(option);
    }
  };

  const selectedValue = _.isString(props.value)
    ? props.value
    : props.value?.value;
  const displayValue = _.isString(props.value)
    ? props.value
    : props.value?.text;

  return (
    <View style={[styles.root, props.style]}>
      <Menu
        visible={isOpen}
        onDismiss={() => setIsOpen(false)}
        anchor={
          <TouchableRipple
            onPress={() => setIsOpen(!isOpen)}
            onLayout={onLayout}>
            <FormTextInput
              value={displayValue || props.defaultText}
              label={props.label}
              inputProps={{
                pointerEvents: 'none',
                left: props.icon && (
                  <TextInput.Icon icon={() => <>{props.icon}</>} />
                ),
                right: (
                  <TextInput.Icon
                    name="menu-down"
                    forceTextInputFocus={false}
                    onPress={() => {
                      setIsOpen(!isOpen);
                    }}
                  />
                ),
              }}
            />
          </TouchableRipple>
        }
        style={{
          maxWidth: inputLayout?.width,
          width: inputLayout?.width,
          position: 'fixed',
        }}>
        <ScrollView style={{maxHeight: 200}}>
          {props.options.map((option, index) => {
            const optionValue = _.isString(option) ? option : option.value;
            const optionText = _.isString(option) ? option : option.text;
            return (
              <Menu.Item
                key={index}
                selected={optionValue === selectedValue}
                title={optionText}
                onPress={() => onOptionPress(option)}
              />
            );
          })}
        </ScrollView>
      </Menu>
    </View>
  );
};

export default Select;
