import React from 'react';
import {View} from 'react-native';

import styles from './styles';
import Text from '../Text';

export const PersonaSize = {
  size32: 32,
  size40: 40,
  size48: 48,
  size56: 56,
};

const Persona = ({
  imageInitials,
  discStyle = null,
  size = PersonaSize.size40,
  selected = false,
  is_dealer = false,
}) => {
  const height = size;
  const width = size;

  return (
    <View style={styles.root}>
      <View
        style={[
          styles.disc,
          {width, height},
          selected ? styles.selected : null,
          is_dealer ? styles.dealer : null,
          discStyle,
        ]}>
        <Text
          style={[
            styles.text,
            {
              fontSize: Math.round(size / 2.5),
              lineHeight: Math.round(size / 2.5),
            },
          ]}>
          {imageInitials}
        </Text>
      </View>
    </View>
  );
};

export default Persona;
