import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  root: {},
  disc: {
    backgroundColor: 'rgba(41, 7, 227, 0.3)',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  },
  selected: {
    borderWidth: 4,
    borderColor: '#181617',
  },
  dealer: {
    backgroundColor: 'rgba(255, 108, 0, 0.3)',
  },
  text: {
    fontWeight: '600',
    color: 'white',
  },
});
