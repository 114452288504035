import React from 'react';
import {View} from 'react-native';
import {useIsFocused} from '@react-navigation/core';

const withScreenIsFocused = (Screen) => {
  return (props) => {
    const isFocused = useIsFocused();

    if (!isFocused) {
      return <View />;
    }

    return <Screen {...props} />;
  };
};

export default withScreenIsFocused;
