import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    backgroundColor: '#fff',
    paddingHorizontal: 20,
    paddingVertical: 8,
  },
  title: {
    paddingVertical: 24,
    fontSize: 24,
    lineHeight: 30,
  },
  button: {
    marginTop: 28,
    padding: 12,
  },
});
