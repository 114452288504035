import {StyleSheet} from 'react-native';

const styles = {
  checkmark: {
    position: 'relative',
    borderRadius: 90,
    borderWidth: 3,
    borderColor: '#299976',
    width: 150,
    height: 150,
  },
  vector1: {
    top: 83,
    left: 29,
    height: 3,
    width: 42,
    transform: [{rotate: '-135deg'}],
    backgroundColor: '#299976',
  },
  vector2: {
    top: 69,
    left: 53,
    height: 3,
    width: 73,
    transform: [{rotate: '-45deg'}],
    backgroundColor: '#299976',
  },
};

export default StyleSheet.create(styles);
