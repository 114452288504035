import React, {useEffect, useState} from 'react';
import {Pressable} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';

import styles from './styles';
import Text from '../Text';

const Chip = ({label, active, code, onPress}) => {
  const [isActive, setActive] = useState(active);

  useEffect(() => {
    setActive(active);
  }, [active]);

  const _onPress = () => {
    setActive(!isActive);

    if (onPress) {
      onPress(code, !isActive);
    }
  };

  return (
    <Pressable
      style={[styles.chip, isActive && styles.chipActive]}
      onPress={_onPress}>
      {isActive && <Icon style={styles.chipIcon} size={12} name="check" />}
      <Text style={isActive ? styles.chipLabelActive : styles.chipLabel}>
        {label}
      </Text>
    </Pressable>
  );
};

export default React.memo(Chip);
