import React, {useState, useEffect, useCallback} from 'react';
import {
  View,
  TextInput,
  SafeAreaView,
  Image,
  ImageBackground,
} from 'react-native';

import {Camera, FormSheetModal, Pressable, PrimaryButton} from '../../controls';
import styles from './styles';
import AddAPhotoIcon from '../../../images/md-icons/add_a_photo/materialicons/24px.svg';
import CloseIcon from '../../../images/md-icons/close/materialicons/24px.svg';

const MIN_HEIGHT = 24;

const RichTextField = ({
  inputViewStyle,
  style,
  uploadsStyle,
  defaultValue,
  defaultImages,
  placeholder,
  buttons,
  onValueChanged,
  onImagesChanged,
}) => {
  const [value, setValue] = useState(defaultValue || '');
  const [images, setImages] = useState(defaultImages || []);
  const [showCamera, setShowCamera] = useState(false);
  const [showImage, setShowImage] = useState(null);
  const [height, setHeight] = useState(MIN_HEIGHT);

  useEffect(() => {
    if (onValueChanged) {
      onValueChanged(value);
    }
  }, [onValueChanged, value]);

  useEffect(() => {
    if (onImagesChanged) {
      onImagesChanged(images);
    }
  }, [onImagesChanged, images]);

  const onPhotoTaken = useCallback(
    ({width, height, data}) => {
      images.push({
        width,
        height,
        src: data,
      });
      setImages(images.slice(0));

      setShowCamera(false);
    },
    [images],
  );

  return (
    <View style={{position: 'relative'}}>
      {images?.length > 0 && (
        <>
          <View style={[styles.uploads, uploadsStyle]}>
            {images.map((data, index) => (
              <Pressable
                key={index}
                style={{position: 'relative', margin: 10}}
                onPress={() => {
                  setShowImage({src: data.src, index});
                }}>
                <Image
                  style={{width: 80, height: 80, resizeMode: 'cover'}}
                  source={{uri: data.src}}
                />
              </Pressable>
            ))}
          </View>
          <FormSheetModal
            visible={showImage !== null}
            animationType="slide"
            onRequestClose={() => {
              setShowImage(null);
            }}>
            <SafeAreaView style={{flex: 1, backgroundColor: '#000'}}>
              <ImageBackground
                style={styles.imageView}
                imageStyle={{resizeMode: 'contain'}}
                source={{uri: showImage?.src}}>
                <View style={styles.closeButtonView}>
                  <Pressable
                    style={styles.closeButton}
                    onPress={() => setShowImage(null)}>
                    <CloseIcon fill="#ffffff" />
                  </Pressable>
                </View>
                <View style={styles.deleteButtonView}>
                  <PrimaryButton
                    onPress={() => {
                      images.splice(showImage.index, 1);
                      setImages(images.slice(0));
                      setShowImage(null);
                    }}>
                    Verwijderen
                  </PrimaryButton>
                </View>
              </ImageBackground>
            </SafeAreaView>
          </FormSheetModal>
        </>
      )}
      <View style={[styles.inputViewStyle, inputViewStyle]}>
        <View>
          <Camera
            visible={showCamera}
            onPhotoTaken={onPhotoTaken}
            onDismiss={() => setShowCamera(false)}
          />
          <Pressable
            disabled={images?.length >= 3}
            onPress={() => setShowCamera(true)}>
            <AddAPhotoIcon style={{opacity: images?.length >= 3 ? 0.5 : 1}} />
          </Pressable>
        </View>
        <TextInput
          style={[styles.input, style, {height}]}
          autoCompleteType="off"
          value={value}
          multiline={true}
          placeholder={placeholder}
          placeholderTextColor="#dcdcdc"
          onChangeText={(text) => setValue(text)}
          onContentSizeChange={(e) =>
            setHeight(Math.max(e.nativeEvent?.contentSize.height, MIN_HEIGHT))
          }
        />
        {buttons && <View>{buttons}</View>}
      </View>
    </View>
  );
};

export default React.memo(RichTextField);
