import {StyleSheet} from 'react-native';

import main from './main';
import header from './header';
import button from './button';
import link from './link';
import content from './content';
import footer from './footer';
import fontSizes from './font-sizes';

export default StyleSheet.create({
  ...main,
  ...header,
  ...content,
  ...footer,
  ...button,
  ...link,
  ...fontSizes,
});
