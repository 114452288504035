import React from 'react';
import {View} from 'react-native';

import Pressable from '../Pressable';
import Text from '../Text';
import styles from './styles';
import globalStyles from '../../../styles';

const DefaultButton = ({disabled, onPress, style, textStyle, children}) => {
  if (disabled) {
    return (
      <View
        style={[
          globalStyles.buttonView,
          styles.button,
          styles.disabled,
          style,
        ]}>
        <Text style={[styles.text, textStyle]}>{children}</Text>
      </View>
    );
  }

  return (
    <Pressable
      onPress={onPress}
      style={[globalStyles.buttonView, styles.button, style]}>
      <Text style={[styles.text, textStyle]}>{children}</Text>
    </Pressable>
  );
};

export default DefaultButton;
