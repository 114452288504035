import {StyleSheet} from 'react-native';
export default StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    backgroundColor: '#fff',
    /*
    shadowOffset: {width: 5, height: 2},
    shadowOpacity: 0.1,
    shadowColor: '#000',
    shadowRadius: 10,
    elevation: 3,
    */
  },
  icon: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
  },
  iconStyle: {
    color: '#231fda',
  },
});
