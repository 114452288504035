import React from 'react';
import CloseIcon from '../../../images/md-icons/close/materialicons/24px.svg';

import Pressable from '../Pressable';
import styles from './styles';

const CloseButton = ({onPress, style}) => {
  return (
    <Pressable style={[styles.view, style]} onPress={onPress}>
      <CloseIcon />
    </Pressable>
  );
};

export default CloseButton;
