import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from 'react';
import {View} from 'react-native';
import _ from 'lodash';
import {HelperText, TextInput} from 'react-native-paper';

import Format from '../../../lib/format';
import FocusManagerContext from '../../context/FocusManagerContext';

const FormNumberInput = ({
  style,
  value,
  digits = 2,
  label,
  required,
  errorMessage,
  inputProps,
  onChangeNumber,
}) => {
  const inputRef = useRef();
  const {setFocus} = useContext(FocusManagerContext);

  const [displayValue, setDisplayValue] = useState(
    _.isString(value) ? value : Format.number(value, digits),
  );

  useEffect(() => {
    setDisplayValue(_.isString(value) ? value : Format.number(value, digits));
  }, [value, digits]);

  const [error, setError] = useState('');

  const _onChangeText = (text) => {
    setDisplayValue(text);
    if (!text.length && required) {
      setError(errorMessage);
      return;
    }

    setError(null);
  };

  const onChange = useCallback(() => {
    const normalized = displayValue.replace(/,/g, '.');

    let number = null;
    if (normalized.length) {
      number = digits === 0 ? parseInt(normalized) : parseFloat(normalized);
    }

    if (
      onChangeNumber &&
      Format.number(number, digits) !== Format.number(value, digits)
    ) {
      onChangeNumber(number);
    }

    return number;
  }, [displayValue, value, digits, onChangeNumber]);

  const onBlur = useCallback(() => {
    const number = onChange();
    setDisplayValue(Format.number(number, digits));
    setFocus(null);
  }, [digits, onChange, setFocus]);

  const onFocus = useCallback(() => {
    setFocus(inputRef.current);
  }, [setFocus]);

  return (
    <View style={style}>
      <TextInput
        ref={inputRef}
        dense={true}
        label={label}
        value={displayValue}
        onChangeText={(text) => _onChangeText(text)}
        onBlur={onBlur}
        onFocus={onFocus}
        keyboardType={digits === 0 ? 'numeric' : 'decimal-pad'}
        error={!!error}
        {...inputProps}
      />
      <HelperText type="error" visible={!!error}>
        {error}
      </HelperText>
    </View>
  );
};

export default FormNumberInput;
