import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  root: {
    flexGrow: 0,
    flexShrink: 0,
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    backgroundColor: '#fff',
    borderWidth: 2,
    borderColor: '#828282',
    borderRadius: 50,
    height: 40,
    width: 40,
  },
  value: {
    minWidth: 150,
    textAlign: 'center',
  },
  icon: {
    color: '#828282',
  },
});
