import {StyleSheet} from 'react-native';

const boldStyle = {fontWeight: '500'};
const italicStyle = {fontStyle: 'italic'};
const codeStyle = {fontFamily: 'Menlo'};

export default StyleSheet.create({
  b: boldStyle,
  strong: boldStyle,
  i: italicStyle,
  em: italicStyle,
  pre: codeStyle,
  code: codeStyle,
  a: {
    fontWeight: '500',
    color: '#007AFF',
  },
  img: {
    minWidth: 50,
    minHeight: 150,
    resizeMode: 'contain',
  },
  closeButtonView: {
    height: 60,
    paddingLeft: 16,
    justifyContent: 'center',
  },
  closeButton: {
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  },
  imageView: {
    flex: 1,
    backgroundColor: '#000',
    zIndex: 0,
  },
});
