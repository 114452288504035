import React, {useEffect, useRef} from 'react';

const Camera = ({visible, onPhotoTaken, onDismiss}) => {
  const fileRef = useRef(null);

  useEffect(() => {
    if (visible && fileRef.current) {
      fileRef.current.click();
      // Because there's no way to keep track if the user canceled the file browser window.
      // We already dismiss the camera now.
      onDismiss();
    }
  }, [visible, fileRef, onDismiss]);

  const handleFile = (e) => {
    e.persist();
    const fileList = e.target.files;

    if (fileList.length) {
      const imageFile = fileList[0];

      if (imageFile.type.indexOf('image/') !== 0) {
        console.log('File is not an image file', imageFile);
        return;
      }

      const reader = new FileReader();
      reader.onload = function (event) {
        onPhotoTaken({data: event.target.result});

        e.target.value = '';
      };
      reader.readAsDataURL(imageFile);
    }
  };

  return (
    <>
      <input
        ref={fileRef}
        style={{display: 'none'}}
        type="file"
        accept="image/*"
        onChange={handleFile}
      />
    </>
  );
};

export default Camera;
