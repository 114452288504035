import React, {useState, useEffect, useContext, useRef} from 'react';
import {View} from 'react-native';
import {HelperText, TextInput} from 'react-native-paper';

import FocusManagerContext from '../../context/FocusManagerContext';

const FormTextInput = (props) => {
  const {
    inputRef,
    style,
    value,
    label,
    inputProps,
    required,
    errorMessage,
    hasError,
    onChangeText,
    onEndEditing,
  } = props;

  let internalInputRef = useRef(null);
  const {setFocus} = useContext(FocusManagerContext);

  const [error, setError] = useState('');

  const _onChangeText = (text) => {
    if (onChangeText) {
      onChangeText(text);
    }

    if (!text.length && required) {
      setError(errorMessage);
      return;
    }

    setError(null);
  };

  useEffect(() => {
    if (hasError) {
      setError(errorMessage);
    } else {
      setError(null);
    }
  }, [hasError, errorMessage]);

  const onFocus = () => {
    setFocus(internalInputRef.current);

    if (props.onFocus) {
      props.onFocus();
    }
  };

  const onBlur = () => {
    if (onEndEditing) {
      onEndEditing();
    }

    setFocus(null);

    if (props.onBlur) {
      props.onBlur();
    }
  };

  return (
    <View style={style}>
      <TextInput
        ref={(ref) => {
          internalInputRef.current = ref;
          inputRef && (inputRef.current = ref);
        }}
        dense={true}
        label={label}
        value={value || ''}
        onChangeText={(text) => _onChangeText(text)}
        onBlur={onBlur}
        onFocus={onFocus}
        onEndEditing={onEndEditing}
        error={!!error}
        {...inputProps}
      />
      <HelperText type="error" visible={!!error}>
        {error}
      </HelperText>
    </View>
  );
};

export default FormTextInput;
