import React from 'react';
import {View} from 'react-native';

import OrderRequestStatus from '../../../types/OrderRequestStatus';
import EmailIcon from '../../../images/md-icons/email/materialicons/24px.svg';
import AssignmentIcon from '../../../images/md-icons/assignment/materialicons/24px.svg';
import AssignmentLateIcon from '../../../images/md-icons/assignment_late/materialicons/24px.svg';
import AssignmentReturnIcon from '../../../images/md-icons/assignment_return/materialicons/24px.svg';
import AssignmentTurnedInIcon from '../../../images/md-icons/assignment_turned_in/materialicons/24px.svg';

const OrderRequestStatusIcon = ({style, status, iconProps = {}}) => {
  let icon;
  switch (status) {
    case OrderRequestStatus.Created: {
      icon = <EmailIcon fill="#231fda" {...iconProps} />;
      break;
    }
    case OrderRequestStatus.Pending: {
      icon = <AssignmentReturnIcon fill="#231fda" {...iconProps} />;
      break;
    }
    case OrderRequestStatus.Rejected: {
      icon = <AssignmentLateIcon fill="#972727" {...iconProps} />;
      break;
    }
    case OrderRequestStatus.Approved:
    case OrderRequestStatus.Ready: {
      icon = <AssignmentIcon fill="#299976" {...iconProps} />;
      break;
    }
    case OrderRequestStatus.Finished: {
      icon = <AssignmentTurnedInIcon fill="#299976" {...iconProps} />;
      break;
    }
    default: {
      icon = null;
      break;
    }
  }

  return <View style={style}>{icon}</View>;
};

export default OrderRequestStatusIcon;
