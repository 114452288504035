import {useContext, useRef, useEffect} from 'react';
import _ from 'lodash';

import SessionStateContext from '../components/context/SessionStateContext';
import DispatchContext from '../components/context/DispatchContext';

export const useSelector = (selector) => {
  const sessionState = useContext(SessionStateContext);
  return selector(sessionState);
};

const createDispatchFunc = (dispatch) => (action) => {
  if (_.isFunction(action)) {
    const exec = async () => {
      await action(dispatch);
    };

    exec();
  } else {
    dispatch(action);
  }
};

export const useDispatch = () => {
  const dispatch = useContext(DispatchContext);

  const func = useRef(createDispatchFunc(dispatch));

  useEffect(() => {
    func.current = createDispatchFunc(dispatch);
  }, [dispatch]);

  return func.current;
};
